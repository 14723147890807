import axios, {AxiosResponse} from 'axios';
import {timer} from 'rxjs';
import {addErrorHandler, AppError, getAppStatus, LOAD_ERROR, registerApplication, unregisterApplication, start, getAppNames} from 'single-spa';
import {AppDataByCliRole, ApplVer, RootSpaCnfg, ApplWidget, HasuraResponse, ParcelMap} from './classes/root-config.classes';
import GlobalEventBus from './customProps/GlobalEventBus';
import GlobalEventStore from './customProps/GlobalEventStore';
import ParcelHelpers from './customProps/ParcelHelpers';
import {applSclRefId} from './enums/root-config.enums';
import { CliFuncRoleAppl } from './classes/root-config.classes';

const jwtDecode = require("jwt-decode");

const globalEventBus = new GlobalEventBus();
const globalEventStore = new GlobalEventStore();


// for running single spa locally, below variable should point to dev proxy
export const userManagementUrl = isLocal() ? "https://dev-ecp-api.optum.com/user/v1/graphql" : 'http://user-management-hasura-graphql-engine-dynamickey.core/v1/graphql';
// export const optumCareUserManagementUrl = isLocal() ? 'http://user-config-mgmt-hasura-graphql-engine-user-fs.default/v1/graphqlg' : '${OPTUMCARE_USER_MANAGEMENT_URL}';
// export const housecallsUserManagementUrl = isLocal() ? "https://dev-ecp-api.optum.com/user/v1/graphql" : 'http://user-management-hasura-graphql-engine-dynamickey.core/v1/graphql';
export const configMngmtUrl = isLocal() ? "https://dev-ecp-api.optum.com/config/api/anonymous/props/" : '${CONFIG_MANAGEMENT_URL}';
export const schedulingHCHostname = "scheduling.uhchousecalls.com";
export let currentDns = window.location.hostname;
const digitalMemberUiApplNm = "hc-digital-member-ui";
const schedulerApplNm = "hc_scheduler";
const consoleApplNm = "system_mgmt";
const localDns = "dev-clinical.optum.com";
const hcDigitalMemberPath = "hc-digital-member";
let hostname = "";
let applNm = "";
let versionId = "1.0.0";
const pageTitle = "OCM";
const nestedWidgetSectionPrefix = "router_%";
let excludedAppRegistrations = [];
const consoleBetaStorageKey = '#console-beta-enabled';
const claims = 'x-ecp-claims';
export interface IEcpClaims {
  "x-ecp-claims": IXEcpClaims;
  "https://hasura.io/jwt/claims": IHasuraClaims;
  "scope": string;
  "iss": string;
  "exp": number;
  "client_id": string;
}

export declare interface IHasuraClaims {
  "x-hasura-default-role": string;
  "x-hasura-attrs": string;
  "x-hasura-cli-org": string;
  "x-hasura-user-id": string;
  "x-hasura-func-role": string;
  "x-hasura-allowed-roles": string[];
}

export declare interface IXEcpClaims {
  "x-ecp-attrs": Map<string,string>;
  "x-ecp-alt-user-id": string;
  "x-ecp-cli-orgs": IXEcpCliOrgs[];
  "x-ecp-first-name": string;
  "x-ecp-type": string;
  "x-ecp-user-id": string;
  "x-ecp-email": string;
  "x-ecp-last-name": string;
  "x-ecp-source": string;
}

export declare interface IXEcpCliOrgs {
  "func-roles": IFuncRole[];
  "org-id": string;
}

export declare interface IFuncRole {
  "appl-roles": string[];
  "role-name": string;
}

export declare interface IPingFedClaims {
  acr: string;
  aud: string;
  auth_time: number;
  email: string;
  employeeID: string;
  exp: number;
  firstName: string;
  iat: number;
  iss: string;
  jti: string;
  lastName: string;
  nonce: string;
  'pi.sri': string;
  sub: string;
}

export const DEFAULT_CLIENT = {
  ACTIVE_KEY: 'active_org_role',
  AVAILABLE_ORG_ROLE: 'available_org_role'
};
// storage
export const ECP_TOKEN = {
  KEY: 'ecp_token',
  EXP_KEY: 'ecp_token_expires_at',
  STORE_KEY: 'ecp_token_stored_at',
  USER_INACTIVE_SECONDS: 'user_inactive_seconds',
  CLAIMS_KEY: 'ecp_token_claims_obj',
  DISABLE_AUTO_LOGIN: 'disable_auto_login',
  ID_TOKEN_CLAIMS: 'id_token_claims_obj'
};

export declare interface OrgRole {
  org: string;
  role: string;
}


export class CnfgVal {
  root_spa_config: RootSpaCnfg;
}

let landingAppConfig: ApplVer;
let appNames$: any;
const parcelMap: ParcelMap = new ParcelMap();

function init() {
  buildPropertiesBasedOnDns(currentDns);
  const element = document.getElementById("page-title");
  if (element) {
    element.innerText = pageTitle;
  }
  console.log("single-spa divs loaded:");
  console.log(document.getElementsByClassName("spa-app"));
  registerSpaAndWatchForToken(currentDns);
}

export function setApplNmAndVersionIdForLocal() {
    if (consoleBetaEnabled()) {
      setConsoleBetaStorage(true);
      // userManagementCdsUiConfig.appl_endpt_url = "/microproduct/user-management-cds-ui-2-0-service.core.svc.cluster.local/main.js";
      versionId = "2.0.0";
    }
    currentDns = localDns;
    applNm = consoleApplNm;
}

export function setApplNmAndVersionId(currentDns) {
  if(currentDns.includes(schedulingHCHostname)) {
    const pathName = window.location.pathname;
    console.log("pathname"+pathName);
    if (pathName?.includes(hcDigitalMemberPath)) {
      applNm = digitalMemberUiApplNm;
    }else {
      applNm = schedulerApplNm;
    }
  } else {
    applNm = consoleApplNm;
  }
}

export function buildPropertiesBasedOnDns(currentDns) {
  if (currentDns.includes("localhost") || localDns === currentDns) {
    setApplNmAndVersionIdForLocal();
  } else {
    setApplNmAndVersionId(currentDns);
  }
}


/**
 * set session storage item
 * @param val
 */
export function setConsoleBetaStorage(val: boolean): void {
  sessionStorage.setItem(consoleBetaStorageKey, String(val));
}

export function consoleBetaEnabled(): boolean {
  return isLocalOrDev() && (location.hash.includes(consoleBetaStorageKey) || sessionStorage.getItem(consoleBetaStorageKey) === 'true');
}

function registerSpaAndWatchForToken(currentDns) {
  addSingleSpaErrorHandler();
  getConfigValue(applNm, versionId, currentDns);
}

/**
 * Register apps
 * - Running local will point to dev env or local main.js files if they are used.
 */
export function registerSingleSpaApplication(app: ApplVer) {
  if (app?.appl_endpt_url && app.bas_mnu_url) {
    registerWidgets(app);
    registerChildAppsAndParcels(app);
    const parcelHelpers = new ParcelHelpers(app.appl_nm, parcelMap.get(app.appl_nm)); // get map of parcel for parent app
    const appUrl = getAppUrl(app.appl_endpt_url);
    console.log("Registering app:", app.appl_nm, `v${app.appl_ver_id}`, 'at', app.bas_mnu_url);
    try {
      registerApplication({
        name: app.appl_nm,
        app: () => System.import(appUrl),
        activeWhen: app.bas_mnu_url,
        customProps: { globalEventBus, globalEventStore, parcelHelpers },
      });
    }
    catch (error) {
      console.error(`Failed to register.`, error);
    }
  }
}

/**
 * loop through the appl_widgets and look for nested applications to mount
 * @deprecated, will be replaced with nestedApps defined in table `appl_ver_chld`
 * @param app
 * @deprecated - will be replaced w/ child apps
 */
export function registerWidgets(app: ApplVer) {
  app?.appl?.appl_widgets?.forEach(widget => {
    if(widget?.widget_desc?.appl_endpt_url && widget?.widget_desc?.route) {
      registerWidget(widget, app);
    }
  });
}

/**
 * loop through nested apps v2
 * @param app
 */
export function registerChildAppsAndParcels(app: ApplVer) {
  app?.appl_ver_chlds?.forEach(chldAppl => {
    if (chldAppl.chld_appl?.appl?.appl_scl_ref_id === applSclRefId.Parcel) {
      registerParcel(app.appl_nm, chldAppl.chld_appl);
    } else if(chldAppl.chld_appl?.appl?.appl_scl_ref_id === applSclRefId.NestedProduct) {
      registerChildApp(app, chldAppl.chld_appl);
    }
    registerChildAppsAndParcels(chldAppl?.chld_appl);
  });
}

export function registerParcel(parentApplName: string, applVer: ApplVer) {
  console.log(`Registering parcel:`, applVer?.appl_nm, 'within app', parentApplName)
  const parcel = {...applVer, appl_endpt_url: getAppUrl(applVer?.appl_endpt_url)}
  parcelMap.push(parentApplName, parcel.appl_nm, parcel);
}

/**
 * v2 evolution of nested widgets
 * @param parentAppl
 * @param childAppl
 */
export function registerChildApp(parentAppl: ApplVer, childAppl: ApplVer) {
  const parentApplNm = parentAppl?.appl_nm;
  const childApplNm = childAppl?.appl_nm;
  const registeredApplNm = `widget_${parentApplNm}_${childApplNm}`;
  const isChildRegisteredAlready: boolean = !!getAppNames().find(a => a === registeredApplNm);
  if (!isChildRegisteredAlready) {
    const nestedRoute = getNestedRoute(childAppl?.bas_mnu_url, parentAppl?.bas_mnu_url);
    const childApplUrl = getAppUrl(childAppl?.appl_endpt_url);
    const parcelHelpers = new ParcelHelpers(childApplNm, parcelMap.get(childApplNm)); // get map of parcel for child app
    if (nestedRoute && childApplUrl) {
      console.log('Registering child app:', registeredApplNm, 'at', nestedRoute);
      try {
        registerApplication({
          name: registeredApplNm,
          app: () => System.import(childApplUrl),
          activeWhen: (location: Location) => {
            return location.pathname.match(nestedRoute) !== null;
          },
          customProps: { globalEventBus, globalEventStore, parcelHelpers },
        });
      } catch (e) {
        console.log("Failed to register child app", e);
      }
    }
  }
}

/**
 * for local environment point to dev otherwise use URl as is
 * @param applUrl
 */
export function getAppUrl(applUrl: string): string {
  if (isLocal()) {
    return applUrl.startsWith('/microproduct/') ? `https://dev-${hostname}${applUrl}` : applUrl;
  }
  return applUrl;
}

/**
 * register a nested widget, matching on named angular router
 * widgets are named and linked to parent mp path
 * @param widget
 * @param parentApp
 * @deprecated - will be replaced w/ child apps
 */
export function registerWidget(widget: ApplWidget, parentApp: ApplVer) {
  const nestedRoute = getNestedRoute(widget?.widget_desc['route'], parentApp.bas_mnu_url);
  const applUrl = getAppUrl(widget?.widget_desc?.appl_endpt_url);
  const appName = `widget_${parentApp?.appl_nm}_${widget?.widget_nm}`;

  if (nestedRoute && applUrl) {
    console.log('Registering nested widget:', appName, 'at', nestedRoute)
    try {
      registerApplication({
        name: appName,
        app: () => System.import(applUrl),
        activeWhen: (location: Location) => {
          return location.pathname.match(nestedRoute) !== null;
        },
        customProps: { globalEventBus, globalEventStore },
      });
    } catch (e) {
      console.log("Failed to register widget", e);
    }
  }
}

start({ urlRerouteOnly: true });

init();

export function watchEcpTokenInStorage(value: RootSpaCnfg): void {
  const ecp_token = localStorage.getItem("ecp_token");
  if (!ecp_token || window.location.pathname === '/sso') {
    appNames$ = timer(200);
    appNames$.subscribe(() => {
      watchEcpTokenInStorage(value);
    });
  } else {
    loadUsersApps(ecp_token, value);
  }
}

export function getConfigValue(applnm: string, version: string, key: string) {
  const managementServiceConfigUrl = configMngmtUrl  + applnm + "/" + version + "/" + key;
  axios.get(managementServiceConfigUrl).then(function (response) {
    try {
      let jsonString = response?.data?.[0]?.value
      if (jsonString) {
        let config: ApplVer = JSON.parse(jsonString);
        landingAppConfig = config;
        let value: RootSpaCnfg = config.root_spa_config;
        hostname = config?.hostName;
        excludedAppRegistrations = config?.excludedAppRegistrations;
        if (consoleBetaEnabled()) {
          config.appl_endpt_url =  "/microproduct/user-management-cds-ui-2-0-service.core.svc.cluster.local/main.js";
        } else {
        config.appl_endpt_url = config?.root_spa_config.login_ui_endpt_url
        }
        registerSingleSpaApplication(config);
        watchEcpTokenInStorage(value);
      }
    } catch (e) {
      console.log('error decoding ecp claims', e?.message);
    }
  }).catch(function (error) {
    console.error(`Request to ${managementServiceConfigUrl} failed. \n\n ${error}.`);
  });
}

function loadUsersApps(ecp_token: string, value: RootSpaCnfg) {
  const decoded_ecp_token = jwtDecode(ecp_token);
  const cli_orgs_array = decoded_ecp_token["x-ecp-claims"]["x-ecp-cli-orgs"];
  const cli_orgs: string[] = [];
  const func_roles: string[] = [];
  cli_orgs_array.forEach((cli_org_object) => {
    cli_orgs.push(cli_org_object["org-id"]);
    const func_roles_array = cli_org_object["func-roles"];
    func_roles_array.forEach((func_role_object) => {
      func_roles.push(func_role_object["role-name"]);
    });
  });
  if (cli_orgs.length && func_roles.length) {
    axios.post(userManagementUrl, getUsersApps(cli_orgs, func_roles), getUserAppsHeaders(ecp_token))
        .then((response: AxiosResponse<HasuraResponse<AppDataByCliRole>>) => {
          const apps = response?.data?.data?.cli_func_role_appl || [];
          // filter and map nested micro products for loading
          const app_names = apps
              .filter((app) => landingAppConfig?.appl_nm !== app.appl_ver?.appl_nm && app.appl_ver?.appl_endpt_url && app.appl_ver?.bas_mnu_url)
              .map(app => ({ name: app.appl_ver?.appl_nm, url: app.appl_ver?.bas_mnu_url }))
          localStorage.setItem("app_names", JSON.stringify(app_names));
          appNames$ = null;
          registerApps(apps, value);
          handleGraphqlErrors(response);
        })
        .catch(function (error) {
          console.error(`Request to ${userManagementUrl} failed. \n\n ${error.response}. \n\n Micro products will not load correctly.`);
        });
  }
}

export function registerApps(apps: CliFuncRoleAppl[], value: RootSpaCnfg){
  if (apps && apps.length > 0) {
    const fetchedConsoleConfig = apps.find(a => a.appl_ver?.appl_nm === landingAppConfig?.appl_nm);
    const ver_id =  fetchedConsoleConfig?.appl_ver?.appl_ver_id;
    unregisterAndRegisterConsoleApp(ver_id, fetchedConsoleConfig, value, landingAppConfig);
    // wait for shell app to load nested apps in DOM before registering with single-spa
    waitForElement(`[id^='single-spa-application'] [id^='single-spa-application']`).then(() => {
      apps.forEach((appConfig) => {
        // don't register the same app that was already registered initially OR ones that should be excluded
        if (landingAppConfig?.appl_nm !== appConfig.appl_ver?.appl_nm && !excludedAppRegistrations.includes(appConfig.appl_ver?.appl_nm)) {
          registerSingleSpaApplication(appConfig?.appl_ver);
        }
      });
    })
    .catch(function (error) {
    console.log("Failed here to wait", error);
    });
  }
}

export function unregisterAndRegisterConsoleApp(ver_id: string, fetchedConsoleConfig: CliFuncRoleAppl, value: RootSpaCnfg, landingAppConfig: ApplVer){
  registerChildAppsAndParcels(fetchedConsoleConfig?.appl_ver);
  // for logging into console version
  if (!consoleBetaEnabled()) {
    if (landingAppConfig?.appl_nm === consoleApplNm && versionId !== ver_id) {
      unregisterBasedOnApp(consoleApplNm, ver_id, fetchedConsoleConfig?.appl_ver?.appl_endpt_url, landingAppConfig);
    }
  }
}

export function unregisterBasedOnApp(applNm: string, ver_id: string, appl_endpt_url: string, config: ApplVer) {
  unregisterApplication(applNm)?.then(() => {
    config.appl_ver_id = ver_id;
    config.appl_endpt_url = appl_endpt_url;
    const appUrl = getAppUrl(config.appl_endpt_url);
    const parcelHelpers = new ParcelHelpers(config.appl_nm, parcelMap.get(config.appl_nm));
    setTimeout(() =>
    registerApplication({
      name: config.appl_nm,
      app: () => System.import(appUrl),
      activeWhen: config.bas_mnu_url,
      customProps: { globalEventBus, globalEventStore, parcelHelpers },
    }), 5000);
  })
  .catch(function (error){
    console.error(`Failed to unregister.`, error);
  });
}

/**
 * handle graphql errors
 * @param response
 */
export function handleGraphqlErrors(response: AxiosResponse<HasuraResponse<AppDataByCliRole>>) {
  if (response?.data?.errors) {
    const code = response?.data?.errors?.[0]?.extensions?.code;
    const message = response?.data?.errors?.[0]?.message;
    const errorPrefix = `Request to ${userManagementUrl} failed. \n\n Code: '${code}'. \n Message: '${message}'. \n\n Micro products will not load correctly.`
    switch(code) {
      case "invalid-headers":
        // environment variable `HASURA_GRAPHQL_UNAUTHORIZED_ROLE=single_spa_config` missing on `user-management-hasura-engine` could be the issue
        console.error(`${errorPrefix} \n Hasura validation failed, this is likely due to a configuration issue.`);
        break;
      default:
        console.error(`${errorPrefix}`);
        break;
    }
  }
}

export function addSingleSpaErrorHandler() {
  addErrorHandler((error: AppError) => {
    if (getAppStatus(error.appOrParcelName) === LOAD_ERROR) {
      const errorPrefix = `Micro product ${error.appOrParcelName} failed to load.\n\n Status: '${getAppStatus(error.appOrParcelName)}'. \n Message: '${error.message}'.`;
      console.error(`${errorPrefix} \n\n Confirm the file above is available.`);
    }
  });
}

/**
 * waits for element(s) of provided selector
 * used to prevent race conditions with nested micro products
 * @param selector
 */
export async function waitForElement(selector: string): Promise<any> {
  const start = new Date();
  while ( document.querySelector(selector) === null) {
    await new Promise( resolve => requestAnimationFrame(resolve) )
  }
  console.log(`${selector} took: ${(new Date()).valueOf() - (start).valueOf()} ms to load`) ;
  return document.querySelector(selector);
}

/**
 * get all the apps user in context has client/role access for
 * appl_widgets used for nested apps, requires ui_sect_nm starts with `router_`
 * @param cli_orgs
 * @param func_roles
 */
export function getUsersApps(cli_orgs: string[], func_roles: string[]) {
  const formattedDate = new Date()
    .toISOString()
    .replace(/T.*/, "")
    .split("-")
    .join("-");
  return {
    // do we want order_by: {appl_ver_id: desc}?
    query: `
    query get_users_apps {
      cli_func_role_appl(where: {cli_org_id: {_in: ${JSON.stringify(cli_orgs)}}, func_role_nm: {_in: ${JSON.stringify(func_roles)}},
      _or: [{end_dt: {_is_null: true}}, {end_dt: {_gt: "${formattedDate}"}}]}, order_by: {appl: {appl_scl_ref_id: desc_nulls_last}}) {
        appl_ver {
          appl_nm
          appl_ver_id
          bas_mnu_url
          appl_endpt_url
          appl {
            appl_widgets(where: {ui_sect_nm: {_like: "${nestedWidgetSectionPrefix}"}}) {
              widget_nm
              widget_desc
              ui_sect_nm
            }
            appl_scl_ref_id             # not parcel or child app
          }
          appl_ver_chlds {              # level 1 association
            chld_appl {                 # child app or parcel
              appl_nm
              appl_ver_id
              appl_endpt_url
              bas_mnu_url
              appl {
                appl_scl_ref_id # needs to be child app or parcel
              }
              appl_ver_chlds {          # level 2 association
                chld_appl {             # parcel
                  appl_nm
                  appl_ver_id
                  appl_endpt_url
                  appl {
                    appl_scl_ref_id # needs to be parcel
                  }
                  appl_ver_chlds {      # level 3 association
                    chld_appl {         # parcel (only if within child app)
                      appl_nm
                      appl_ver_id
                      appl_endpt_url
                      appl {
                        appl_scl_ref_id # needs to be parcel
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
        `,
    variables: {},
  };
}

/**
 * headers for retrieving apps user is authorized to access
 * headers for local testing, update x-hasura-admin-secret with current value
 */
export function getUserAppsHeaders(ecp_token: string) {
  const headers = {
    "content-type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem('ecp_token')}`,
  };
  const hasuraRole = getHasuraRoleForHeaders(ecp_token);
  if (hasuraRole) {
    headers['x-hasura-role'] = hasuraRole;
  } else if (Number(sessionStorage.getItem('role-expired')) === 1) {
    delete headers['Authorization'];
    headers['x-hasura-role'] = 'single_spa_config';
  } else if (window.location.pathname !== '/sso') {
    console.error('rootspa Header "x-hasura-role" is missing, this is bad.');
  }
  return { headers: headers };
}

function getHasuraRoleForHeaders(ecp_token: string) {
  const [org, role] = getActiveLoginClientRole(ecp_token);
  return getHasuraRole(org || '', role || '', 'system_mgmt');
}

/**
   * get stored ecp claims from auth service
   *
   * Prioritizes from jwt if it exists
   */
function getEcpClaims(): IEcpClaims {
  try {
    const ecpToken = localStorage.getItem(ECP_TOKEN.KEY);
    if (ecpToken) {
      return  <IEcpClaims>jwtDecode(ecpToken);
    }
  } catch (e) {
    console.log('error decoding ecp claims', e?.message);
  }
  const ecpTokenClaims = localStorage.getItem(ECP_TOKEN.CLAIMS_KEY);
  return <IEcpClaims>JSON.parse(<string>ecpTokenClaims);
}

/**
   * Gets the current active client/role
   * Prioritizes ecp_token values if they exist
   * Will set localStorage `active_org_role` values if mismatch with active org/role in `ecp_token`
   */
function getActiveLoginClientRole(ecp_token: string): [string?, string?] {
  console.log('Im in active login client role');
  const claims : IEcpClaims = getEcpClaims();
  const [org, role] = JSON.parse(localStorage.getItem(DEFAULT_CLIENT.ACTIVE_KEY) || '[]');
  const orgFromClaims = claims?.['x-ecp-claims']?.['x-ecp-cli-orgs']?.[0]?.['org-id'];
  const roleFromClaims = claims?.['x-ecp-claims']?.['x-ecp-cli-orgs']?.[0]?.['func-roles']?.[0]?.['role-name'];
  if (claims && (orgFromClaims !== org || roleFromClaims !== role)) {
    console.warn([orgFromClaims, roleFromClaims], 'org/role mismatch!', [org, role]);
    setActiveLoginClientRole(orgFromClaims, roleFromClaims);
    return [orgFromClaims, roleFromClaims];
  }
  return [org, role];
}

/**
   * Sets the active client/role for the user to login as.
   * Does not determine the current active client/role
   *
   * @param org The default client the user should login as.
   * @param role The default role the user should login as.
   */
export function setActiveLoginClientRole(org?: string, role?: string): void {
  if (org && role) {
    localStorage.setItem(DEFAULT_CLIENT.ACTIVE_KEY, JSON.stringify([org, role]));
  } else if (org) {
    localStorage.setItem(DEFAULT_CLIENT.ACTIVE_KEY, JSON.stringify([org]));
  }
}

/**
   * Finds hasura role based on the org, role, product, and optionally the pattern
   * @param org - org of the user
   * @param persona - role of the user
   * @param product - the name of the product
   * @param pattern - pattern to match for roles that may share a name or prefix.
   *  e.g. prism_* -> prism_write and prism_view_factory_ui_* -> prism_view_factory_ui_write
   */
export function getHasuraRole(org: string, persona: string, product: string, pattern?: string) {
  const applRolesStr = 'appl-roles';
  const orgs = getClientOrgs();
  const claimsOrg = orgs.find(o => o['org-id'] === org);
  if (claimsOrg) {
    const claimsRole = claimsOrg['func-roles'].find(r => r['role-name'] === persona);
    if (claimsRole) {
      let hasuraRole = claimsRole[applRolesStr].find(itm => itm.startsWith(product)) || '';
      if (pattern) {
        const patternSplit = pattern.split('_');
        const productRole = claimsRole[applRolesStr]
          .filter(itm => itm.includes(product))
          .find(role => role.split('_').length === patternSplit.length);

        if (productRole) {
          hasuraRole = productRole;
        }
      }
      return hasuraRole;
    }
  }
  return '';
}

function getClientOrgs(): IXEcpCliOrgs[] {
  return getEcpClaims()?.[claims]?.['x-ecp-cli-orgs'] || [];
}


/**
 * determine if we are running localhost, maybe this should be env var instead?
 */
export function isLocal(): boolean {
  return window.location.host === 'localhost:4200';
}

export function isLocalOrDev(): boolean {
  return window.location.host === 'localhost:4200' || window.location.host === 'dev-clinical.optum.com';
}

/**
 * nested routes use a named angular router outlet
 * they follow the pattern of [parent base url]([outlet name]:[route-path]/[optional-child-path(s)]) in the url
 * @example: /parent-route(ovc_scheduling:route-1/route-2)
 * @param auxRoute
 * @param parentBaseUrl
 * @return nestedRoute, string used to regex if named outlet matches, works with multiple outlets
 */
export function getNestedRoute(auxRoute: string, parentBaseUrl: string): string {
  let nestedRoute: string = null;
  const namedRouteRegex: RegExp = /\((.*):(.*)\)/;                                         // matches angular named route pattern
  const match = auxRoute?.match(namedRouteRegex);
  if (auxRoute && match !== null) {
    nestedRoute = `.*${escapeRegex(parentBaseUrl)}.*(.*${match[1]}:${match[2]}.*)`;      // matcher for single-spa activate function
  }
  return nestedRoute;
}

/**
 * regex escape a string
 * @param string
 */
export function escapeRegex(string: string): string {
  return string?.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}
